import {
  required,
  email,
  max_value,
  min_value,
  regex,
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "{_field_} field is required",
});

extend("mobile", {
  ...regex,
  message: "Please make sure mobile on is properly formatted, like 01712345678",
});

extend("objectNotEmpty", {
  validate: (value, args) => {
    // console.log("validation_value#", value, "args#", args);
    if (value[args.key] === "") {
      return false;
    } else if (value === null) {
      return false;
    } else {
      return true;
    }
  },
  params: ["key"],
  message: "{_field_} field is required",
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Confirmation password does not match",
});

extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
  message: 'The "{_field_}" field must have at least {length} characters',
});

extend("max", {
  validate(value, args) {
    return value.length <= args.length;
  },
  params: ["length"],
  message: 'The "{_field_}" field cannot have more than {length} characters',
});

// No message specified.
extend("email", email);

extend("max_value", max_value);
extend("min_value", {
  ...min_value,
  message: "{_field_} value has to be more or equal to {min}",
});

extend("compare_dates", {
  params: ["target"],
  validate(value, { target }) {
    return value < target;
  },
  message: "{_field_} must be smaller than the {target}",
});
